(<template>
  <div class="availability-day">
    <h4 class="availability-day__title">{{ params.dayName }}</h4>
    <div :class="{'availability-day__selects-wrapper--blocked': isBlockedDay}"
         class="availability-day__selects-wrapper">
      <sk-timepicker :preselected-value="startTime"
                     :default-value="'00:00'"
                     :description="startTimeDescr"
                     :auto-scroll="true"
                     :last-item="false"
                     :mob-numeric-keyboard="true"
                     class="availability-day__timepicker availability-day__timepicker--start"
                     @timepickervaluechanged="setStartTime" />
      <sk-timepicker :preselected-value="finishTime"
                     :default-value="'00:00'"
                     :description="finishTimeDescr"
                     :auto-scroll="true"
                     :last-item="true"
                     :mob-numeric-keyboard="true"
                     :start-select-from="finishTimeSelectFrom"
                     class="availability-day__timepicker availability-day__timepicker--finish"
                     @timepickervaluechanged="setFinishTime" />
    </div>
    <div class="availability-day__btn-cont">
      <button :class="[blockBtnClass]"
              class="sk-link availability-day__block-btn"
              @click="blockFunction">{{ blockBtnText }}</button>
    </div>
  </div>
</template>)

<script>
  export default {
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      isBlockedDay() { return this.params.isBlockedDay; },
      startTimeDescr() { return this.$gettext('Start time'); },
      finishTimeDescr() { return this.$gettext('Finish time'); },
      blockBtnText() { return this.isBlockedDay ? this.$gettext('Unblock day') : this.$gettext('Block day'); },
      blockBtnClass() { return this.isBlockedDay ? '' : 'sk-link--destroy-action'; },
      finishTimeSelectFrom() {
        if (this.params.startTime) {
          const startTimeArr = this.params.startTime.split(':');
          const startTimeMoment = this.$moment()
            .hours(startTimeArr[0])
            .minutes(startTimeArr[1])
            .add(30, 'minutes')
            .format('HH:mm');
          return startTimeMoment === '00:00' ? '23:59' : startTimeMoment;
        }
      },
      startTime() { return this.isBlockedDay ? '00:00' : this.params.startTime; },
      finishTime() { return this.isBlockedDay ? '23:59' : this.params.finishTime; }
    },
    methods: {
      setStartTime(value) {
        const startTimeMoment = this.$moment(value, 'HH:mm');
        const finishTimeMoment = this.$moment(this.params.finishTime, 'HH:mm');
        if (startTimeMoment >= finishTimeMoment) {
          const finishTime = value === '23:30' ? '23:59' : startTimeMoment.add(30, 'minutes').format('HH:mm');
          this.$emit('finishtimechanged', {value: finishTime, id: this.params.day});
        }
        this.$emit('starttimechanged', {value, id: this.params.day});
      },
      setFinishTime(value) {
        this.$emit('finishtimechanged', {value, id: this.params.day});
      },
      blockFunction() {
        this.$emit('handleblockday', {value: !this.isBlockedDay, id: this.params.day});
      }
    }
  };
</script>

<style scoped>
  .availability-day {
    display: flex;
    flex-direction: column;
    width: calc(100% / 7);
  }

  .availability-day__title {
    font-size: 12px;
  }

  .availability-day__timepicker {
    max-width: 75px;
    margin-top: 20px;
  }

  .availability-day__selects-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .availability-day__selects-wrapper--blocked::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background-color: #fff;
    opacity: 0.6;
  }

  .availability-day__block-btn {
    margin-top: 15px;
    text-align: left;
  }

  @media (max-width: 950px) {
    .availability-day {
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      padding-bottom: 20px;
    }

    .availability-day__title {
      flex-shrink: 1;
      overflow: hidden;
      width: calc(100% - 160px);
      margin-right: auto;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .availability-day__timepicker {
      flex-shrink: 0;
      width: 70px;
      max-width: initial;
      margin-top: 0;
      margin-left: 10px;
    }

    .availability-day__selects-wrapper {
      flex-shrink: 0;
      flex-direction: row;
      width: 160px;
      margin-right: auto;
    }

    .availability-day__block-btn {
      position: absolute;
      top: -30px;
    }

    .availability-day__btn-cont {
      position: relative;
      width: 100%;
    }
  }
</style>
