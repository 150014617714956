(<template>
  <div class="event">
    <div v-if="!hideTitle"
         class="event__title">{{ event.title }}</div>
    <slot name="eventIcon"></slot>
    <div class="event__text">
      <div>{{ eventText }}</div>
      <slot name="eventExtraText"></slot>
    </div>
    <button class="event__delete-btn"
            @click="removeEvent"></button>
  </div>
</template>)

<script>
  export default {
    props: {
      event: {
        type: Object,
        default() {
          return {};
        }
      },
      hideTitle: Boolean // event without end date, all day option, title
    },
    computed: {
      isAllDay() { return this.event.allDay; },
      startTime() { return this.getFormattedDate(this.event.startTime, this.isAllDay); },
      finishTime() { return this.getFormattedDate(this.event.finishTime, this.isAllDay); },
      eventText() { return `${this.startTime} - ${this.finishTime}`; }
    },
    methods: {
      getFormattedDate(date, shortFormat) {
        const momentObj = this.$moment(date);
        const month = momentObj.month();
        const monthName = this.$helperData.getMonthNames(this)[month].slice(0, 3);
        const day = momentObj.format('DD');
        const year = momentObj.format('YYYY');
        const time = momentObj.format('HH:mm');
        return shortFormat ? `${day} ${monthName} ${year}` : `${day} ${monthName} ${year} @${time}`;
      },
      removeEvent() {
        this.$emit('deleteevent', this.event.id);
      }
    }
  };
</script>

<style scoped>
  .event {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px 0;
  }

  .event__title {
    width: 100%;
    font-weight: bold;
  }

  .event__delete-btn {
    display: block;
    width: 20px;
    height: 20px;
    margin-left: auto;
    background-image: url(~@assets/imgs/undefined_imgs/trash_ico_black.svg);
    background-position: 50% 50%;
    background-size: 15px auto;
    background-repeat: no-repeat;
  }

  .event__delete-btn:hover {
    opacity: 0.5;
  }

  @media (max-width: 320px) {
    .event__text {
      width: 100%;
    }
  }
</style>
