(<template>
  <page-data-section :title="sectTitle"
                     :description="sectDescr"
                     :progress="progressActive">
    <event v-for="event in privateEvents"
           :key="event.id"
           :event="event"
           @deleteevent="removeEvent" />
    <add-event v-if="showAddEventForm"
               :time-step="timeStep"
               class="private-events__add-event" />
    <button v-if="!showAddEventForm"
            class="sk-btn sk-btn--white private-events__add-btn"
            @click="toggleForm">{{ $gettext("Add another date") }}</button>
    <div v-if="showAddEventForm"
         class="private-events__btn-wrapper">
      <button class="sk-btn sk-btn--default private-events__btn"
              @click="submitData()">{{ $gettext('Save') }}</button>
    </div>
  </page-data-section>
</template>)

<script>
  import {mapActions, mapGetters} from 'vuex';
  import PageDataSection from '@/components/shared_components/page_data/PageDataSection';
  import Event from '@/components/preferences/private_events/OneEvent';
  import AddPrivateEventPrototype from '@/prototypes/AddPrivateEventPrototype';

  export default {
    components: {
      'page-data-section': PageDataSection,
      event: Event
    },
    extends: AddPrivateEventPrototype,
    data() {
      return {
        timeStep: 15,
        showAddEventForm: false,
        progressActive: false
      };
    },
    computed: {
      ...mapGetters('TTPrivateEventsStore', ['privateEvents']),
      sectTitle() { return this.$gettext('Time off'); },
      sectDescr() { return this.$gettext('If you are taking vacation or can\'t work for a period of time you can add these dates here. We will not invite you for assignments that are on these days.'); }
    },
    methods: {
      ...mapActions('TTPrivateEventsStore', ['removePrivateEvent']),
      removeEvent(eventId) {
        this.progressActive = true;
        this.removePrivateEvent(eventId).then(() => {
          this.progressActive = false;
        });
      },
      toggleForm() {
        this.showAddEventForm = !this.showAddEventForm;
        if (this.showAddEventForm) {
          this.setInitPrivateEvent();
        }
      },
      successHandling() {
        this.toggleForm();
      },
      setProgress(value) {
        this.progressActive = value;
      }
    }
  };
</script>

<style scoped>
  .private-events__add-btn {
    display: inline-block;
    align-self: flex-start;
    width: auto;
    margin-top: 15px;
    padding: 0 35px;
  }

  .private-events__btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .private-events__btn {
    width: auto;
    padding: 0 20px;
  }
</style>
