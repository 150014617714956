// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/trash_ico_black.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.event[data-v-7dd106a8] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  padding: 5px 0;\n}\n.event__title[data-v-7dd106a8] {\n  width: 100%;\n  font-weight: bold;\n}\n.event__delete-btn[data-v-7dd106a8] {\n  display: block;\n  width: 20px;\n  height: 20px;\n  margin-left: auto;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-size: 15px auto;\n  background-repeat: no-repeat;\n}\n.event__delete-btn[data-v-7dd106a8]:hover {\n  opacity: 0.5;\n}\n@media (max-width: 320px) {\n.event__text[data-v-7dd106a8] {\n    width: 100%;\n}\n}\n", ""]);
// Exports
module.exports = exports;
