(<template>
  <div>
    <working-hours />
    <private-events class="preference__private-events" />
  </div>
</template>)

<script>
  import WorkingHours from '@/components/preferences/working_hours/WorkingHours';
  import PrivateEvents from '@/components/preferences/private_events/PrivateEvents';

  export default {
    asyncData({store}) {
      let promisesArr = [];
      if (!store.state.TTWorkingHoursStore.workingHoursData) {
        promisesArr = [...promisesArr, store.dispatch('TTWorkingHoursStore/fetchWorkingHours')];
      }
      if (!store.state.TTPrivateEventsStore.privateEvents) {
        promisesArr = [...promisesArr, store.dispatch('TTPrivateEventsStore/fetchPrivateEvents')];
      }
      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    components: {
      'private-events': PrivateEvents,
      'working-hours': WorkingHours
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('TTWorkingHoursStore/clearStore');
      this.$store.commit('TTPrivateEventsStore/clearStore');
      next();
    }
  };
</script>

<style>
  .preference__private-events {
    margin-top: 20px;
  }
</style>
