(<template>
  <page-data-section :title="sectTitle"
                     :description="sectDescr"
                     :progress="progressActive">
    <div class="working-hours">
      <h3 class="working-hours__title">{{ $gettext('Default settings') }}</h3>
      <div class="working-hours__form-cont">
        <one-day v-for="day in workingHoursData"
                 :key="day.dayID"
                 :params="day"
                 class="working-hours__one-day"
                 @starttimechanged="setWorkingHoursStartTime"
                 @finishtimechanged="setWorkingHoursFinishTime"
                 @handleblockday="setWorkingHoursBlockDay" />
      </div>
    </div>
    <div class="working-hours__btn-wrapper">
      <button class="sk-btn sk-btn--default working-hours__btn"
              @click="saveWorkingHours">{{ $gettext('Save') }}</button>
    </div>
  </page-data-section>
</template>)

<script>
  import {mapState, mapMutations, mapActions} from 'vuex';
  import PageDataSection from '@/components/shared_components/page_data/PageDataSection';
  import OneDay from '@/components/preferences/working_hours/WorkingHoursOneDay';

  export default {
    components: {
      'page-data-section': PageDataSection,
      'one-day': OneDay
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapState('TTWorkingHoursStore', {
        workingHours: (state) => state.workingHoursData || []
      }),
      sectTitle() { return this.$gettext('Availability'); },
      sectDescr() { return this.$gettext('Add your daily working hours here to see available jobs during the period you added.'); },
      workingHoursData() {
        for (const day of this.workingHours) {
          const weekDay = this.$moment().day(day.day).day();
          day.dayName = this.$helperData.getDayNames(this)[weekDay];
        }
        return this.workingHours;
      }
    },
    methods: {
      ...mapActions('TTWorkingHoursStore', ['sendWorkingHours']),
      ...mapMutations('TTWorkingHoursStore', ['setWorkingHoursStartTime', 'setWorkingHoursFinishTime', 'setWorkingHoursBlockDay']),
      saveWorkingHours() {
        this.progressActive = true;
        this.sendWorkingHours().then(() => {
          this.progressActive = false;
        });
      }
    }
  };
</script>

<style scoped>
  .working-hours {
    display: block;
    width: 100%;
    margin: 15px 0 30px;
  }

  .working-hours__title {
    font-weight: 600;
    font-size: 14px;
  }

  .working-hours__form-cont {
    display: flex;
    width: 100%;
    margin-top: 15px;
  }

  .working-hours__one-day {
    flex-grow: 1;
    flex-shrink: 0;
    padding-right: 20px;
  }

  .working-hours__btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .working-hours__btn {
    width: auto;
    padding: 0 20px;
  }

  @media (max-width: 950px) {
    .working-hours__form-cont {
      flex-direction: column;
    }

    .working-hours__one-day {
      padding-right: 0;
    }
  }
</style>
